import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src750413537/src/src/layout/defaultLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Section = makeShortcode("Section");
const Stack = makeShortcode("Stack");
const Box = makeShortcode("Box");
const Icon = makeShortcode("Icon");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Section mdxType="Section">
      <h1 {...{
        "id": "frontent-overview"
      }}>{`Frontent Overview`}</h1>
      <p>{`The Rebel front end repos are currently separated into four different repositories.`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#rebeldotcomcomponents"
          }}>{`@rebeldotcom/components`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#rebeldotcomui"
          }}>{`@rebeldotcom/ui`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#rebel-web-src"
          }}>{`rebel-web-src`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#rebel-web-cms"
          }}>{`rebel-web-cms`}</a></li>
      </ul>
      <Stack mt={4} mdxType="Stack">
        <p>{`The `}<em parentName="p">{`rebel-web-components`}</em>{` and `}<em parentName="p">{`rebel-web-ui`}</em>{` both build into installable npm
packages. `}<a parentName="p" {...{
            "href": "https://www.npmjs.com/package/@rebeldotcom/components"
          }}><em parentName="a">{`@rebeldotcom/components`}</em></a>{` and `}<a parentName="p" {...{
            "href": "https://www.npmjs.com/package/@rebeldotcom/ui"
          }}><em parentName="a">{`@rebeldotcom/ui`}</em></a>{`, respectively.`}</p>
      </Stack>
    </Section>
    <Section mdxType="Section">
      <h2 {...{
        "id": "rebeldotcomcomponents"
      }}>{`@rebeldotcom/components`}</h2>
      <Box my={2} mdxType="Box">
        <p><a parentName="p" {...{
            "href": "https://github.com/rebeldotcom/rebel-web-components"
          }}><strong><Box alignItems="center" mdxType="Box"><Icon fill="white" height="2rem" name="github" width="2rem" mr={2} mdxType="Icon" />{`rebel-web-components `}</Box></strong></a></p>
      </Box>
      <p>{`A set of generic, reusable ui components to use as building blocks for more complex components and features.`}</p>
      <p>{`This package is also where the Theme and styling for all of Rebel's modern React components comes from. This repo and package are public and free to use in any project.`}</p>
      <p>{`By wrapping your application with the `}<inlineCode parentName="p">{`<ThemeProvider>`}</inlineCode>{` and using the `}<inlineCode parentName="p">{`<GlobalStyle />`}</inlineCode>{` component, the application's elements will inherit the theme's styles used by styled-system.`}</p>
      <Stack mb={3} mdxType="Stack">
Theming options include:
        <ul>
          <li parentName="ul">{`The default (`}<a parentName="li" {...{
              "href": "https://github.com/rebeldotcom/rebel-web-components/blob/main/src/styles/theme.ts"
            }}>{`theme.ts`}</a>{`)`}</li>
          <li parentName="ul">{`The newer redesign theme (`}<a parentName="li" {...{
              "href": "https://github.com/rebeldotcom/rebel-web-components/blob/main/src/styles/redesignTheme.js"
            }}>{`redesignTheme.ts`}</a>{`)`}</li>
          <li parentName="ul">{`A new custom theme`}</li>
        </ul>
      </Stack>
By passing in either the redesignTheme.ts or a custom theme file, we have control over the design tokens (space, fonts, etc...) used throughout the application.
      <p>{`This package is installed into all Rebel front end applications.`}</p>
    </Section>
    <Section mdxType="Section">
      <h2 {...{
        "id": "rebeldotcomui"
      }}>{`@rebeldotcom/ui`}</h2>
      <Box my={2} mdxType="Box">
        <p><a parentName="p" {...{
            "href": "https://github.com/rebeldotcom/rebel-web-ui"
          }}><strong><Box alignItems="center" mdxType="Box"><Icon fill="white" height="2rem" name="github" width="2rem" mr={2} mdxType="Icon" />{`rebel-web-ui `}</Box></strong></a></p>
      </Box>
      <p>{`A set of features and compound components containing Rebel-specific business logic and configurations.`}</p>
      <p>{`Most Rebel components belong in this repo and package in order to be shared across both site platforms (rebelweb and rebel-web-cms).`}</p>
      <p>{`For example, the Header and Footer as well as authentication flow are used across both old (rebelweb) and new (rebel-web-cms) pages.`}</p>
      <p>{`This package is also installed into all Rebel front end applications.`}</p>
      <Stack mt={3} mdxType="Stack">
        <h4 {...{
          "id": "dependency-notes"
        }}>{`Dependency Notes`}</h4>
        <p><em parentName="p">{`@rebeldotcom/components`}</em>{` is installed into this repo as a peer dependency.`}</p>
        <p>{`This means, while we make use of those UI elements, but don't include the code from
@rebeldotcom/components when bundling @rebeldotcom/ui.`}</p>
        <p>{`Being a peer dependency, @rebeldotcom/components must be installed alongside @rebeldotcom/ui within the application in question.`}</p>
        <p>{`More on this in the deployment section.`}</p>
      </Stack>
    </Section>
    <Section mdxType="Section">
      <h2 {...{
        "id": "rebel-web-src"
      }}>{`rebel-web-src`}</h2>
      <Box my={2} mdxType="Box">
        <p><a parentName="p" {...{
            "href": "https://github.com/rebeldotcom/rebel-web-src"
          }}><strong><Box alignItems="center" mdxType="Box"><Icon fill="white" height="2rem" name="github" width="2rem" mr={2} mdxType="Icon" />{`rebel-web-src `}</Box></strong></a></p>
      </Box>
      <p><em parentName="p">{`rebel-web-src`}</em>{` is the repo containing most of Rebel's legacy React code.`}</p>
      <p>{`Being an interim step in the modernization of the Rebel front end, individual js bundles are created via `}<a parentName="p" {...{
          "href": "https://webpack.js.org/"
        }}>{`Webpack`}</a>{`, each for a specific aspx page in the `}<a parentName="p" {...{
          "href": "https://github.com/rebeldotcom/rebelweb"
        }}>{`rebelweb`}</a>{` solution.`}</p>
      <p>{`As features are migrated to the @rebeldotcom/ui package, this repo should be cleaned up and reduced in size.`}</p>
      <p>{`The current list of features included in this repo includes:`}</p>
      <ul>
        <li parentName="ul">{`Contact Manager - `}<a href="https://www.rebel.com/contacts/manage/" target="_blank">{`live`}</a>{` - `}<a href="https://github.com/rebeldotcom/rebel-web-src/tree/main/src/containers/contact_manager" target="_blank">{`code`}</a></li>
        <li parentName="ul">{`DNS Manager - `}<a href="https://www.rebel.com/dns/manage/?domain=rebel.com" target="_blank">{`live`}</a>{` - `}<a href="https://github.com/rebeldotcom/rebel-web-src/tree/main/src/containers/dns_manager" target="_blank">{`code`}</a></li>
        <li parentName="ul">{`Hosting Manager - `}<a href="https://www.rebel.com/MyAccount/HostingPackages.aspx" target="_blank">{`live`}</a>{` - `}<a href="https://github.com/rebeldotcom/rebel-web-src/tree/main/src/containers/hosting_manager" target="_blank">{`code`}</a></li>
        <li parentName="ul">{`Main Search - `}<a href="https://github.com/rebeldotcom/rebel-web-src/tree/main/src/containers/main_search" target="_blank">{`code`}</a></li>
        <li parentName="ul">{`Message Delivery - `}<a href="https://github.com/rebeldotcom/rebel-web-src/tree/main/src/containers/message_delivery" target="_blank">{`code`}</a></li>
        <li parentName="ul">{`Security Manager - `}<a href="https://www.rebel.com/MyAccount/SecurityManager.aspx" target="_blank">{`live`}</a>{` - `}<a href="https://github.com/rebeldotcom/rebel-web-src/tree/main/src/containers/security_manager" target="_blank">{`code`}</a></li>
        <li parentName="ul">{`Speedy Search - `}<a href="https://www.rebel.com/search/" target="_blank">{`live`}</a>{` - `}<a href="https://github.com/rebeldotcom/rebel-web-src/tree/main/src/containers/speedy-search" target="_blank">{`code`}</a></li>
        <li parentName="ul">{`Rebel - `}<a href="https://github.com/rebeldotcom/rebel-web-src/tree/main/src/containers/rebel" target="_blank">{`code`}</a></li>
      </ul>
      <Box mt={3} mdxType="Box">
        <p>{`The latest and main container is the `}<a parentName="p" {...{
            "href": "https://github.com/rebeldotcom/rebel-web-src/tree/main/src/containers/rebel"
          }}>{`Rebel`}</a>{` container which includes the `}<a parentName="p" {...{
            "href": "https://www.rebel.com/order/"
          }}>{`Order`}</a>{` page. This is also where the main navigation header and footer are added to the aspx pages.`}</p>
      </Box>
    </Section>
    <Section mdxType="Section">
      <h2 {...{
        "id": "rebel-web-cms"
      }}>{`rebel-web-cms`}</h2>
      <Box my={2} mdxType="Box">
        <p><a parentName="p" {...{
            "href": "https://github.com/rebeldotcom/rebel-web-cms"
          }}><strong><Box alignItems="center" mdxType="Box"><Icon fill="white" height="2rem" name="github" width="2rem" mr={2} mdxType="Icon" />{`rebel-web-cms `}</Box></strong></a></p>
      </Box>
      <p>{`The Rebel Web CMS is now the main front end platform for new features as well as all marketing pages and content.`}</p>
      <p>{`Built with `}<a parentName="p" {...{
          "href": "https://www.gatsbyjs.com/docs/"
        }}>{`GatsbyJS`}</a>{`, this solution statically generates the vast majority of it's content and leverages S3 and CloudFront for distribution. The marketing pages rely mostly on predefined `}<a parentName="p" {...{
          "href": "https://github.com/rebeldotcom/rebel-web-cms/tree/main/src/components/panels"
        }}>{`"panel"`}</a>{` components and JSON files. We also have support for mdx files as page sources as well as default-exported React components (.js files).`}</p>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      